import { mapActions, mapState, mapMutations } from "vuex";
import { ACTIONS, MUTATIONS } from "@/constants/vuex";
import dayjs from "@/lib/dayjs";
import { updateReportWidget } from "@/service/dashboard";
import { verifyLanguage } from "@/utils/verifyWidgetLanguage";
import {
  DYNAMIC_SUMMARY,
  LINE_CHART,
  OVERVIEW,
  PIE_CHART,
  BAR_CHART,
  STACK_BAR_CHART,
  TABLE,
  RIVALS,
  MANUAL_KPI,
  PAGE_BREAK,
  LAYOUT_BREAK,
} from "@engenharia/dashgoo-widgets";

export default {
  data: () => ({
    PROCESSABLE_LIST: [
      BAR_CHART.dataType,
      DYNAMIC_SUMMARY.dataType,
      LINE_CHART.dataType,
      OVERVIEW.dataType,
      PIE_CHART.dataType,
      RIVALS.dataType,
      STACK_BAR_CHART.dataType,
      TABLE.dataType,
      PAGE_BREAK.dataType,
      LAYOUT_BREAK.dataType,
    ],
  }),
  computed: {
    ...mapState("dashboard", ["layout", "item"]),
  },
  async mounted() {
    await this.verifyPendingProcessingWidgets();
  },
  methods: {
    ...mapActions({
      $updateWidgetLoad: `dashboard/${ACTIONS.DASHBOARD.UPDATE_WIDGET_LOAD}`,
    }),
    ...mapMutations({
      $updateWidgetLayout: `dashboard/${MUTATIONS.DASHBOARD.UPDATE_WIDGET_LAYOUT}`,
    }),
    verifyPendingProcessingWidgets() {
      if (this.layout.length > 0) {
        const pending = this.layout.some((e) => e.item.updateRequired);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const { updatedAt } = this.item;
        const updatedAsDate = dayjs(updatedAt || today)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0);

        const reportPending = updatedAsDate.diff(today, "days") >= 1;

        if (pending || reportPending) {
          this.selectAndProcessWidgets(!reportPending);
        }
      }
    },
    async selectAndProcessWidgets(onlyRequired = false) {
      const widgets = this.getProcessableWidgets(onlyRequired).reverse();

      const chainItem = widgets.reduce((nextItem, current) => {
        if (!nextItem) {
          return {
            widget: current,
            next: null,
          };
        }

        return {
          widget: current,
          next: nextItem,
        };
      }, null);

      await this.processWidget(chainItem);
    },
    async processWidget(chainList) {
      if (!chainList) {
        this.adjustWidgetPositions();
        return;
      }

      const { next, widget } = chainList;
      const { item } = widget;

      if (item) {
        item.widgetId = widget.i;
        item.widget = widget.i;

        const result = await updateReportWidget(item).catch((err) => {
          item.error = err;
        });
        if (result) {
          this.$updateWidgetLayout(result);
        }
      }

      this.$updateWidgetLoad({
        id: item.widgetId,
        loading: false,
      });

      if (next) {
        return this.processWidget(next);
      }
    },
    adjustWidgetPositions() {
      let offset = 0;

      // Organizar os widgets por y, apenas por segurança.
      this.layout.sort((a, b) => a.y - b.y);

      let widgetsWithData = []; // Armazenar widgets com dados.

      for (let i = 0; i < this.layout.length; i++) {
        let widget = this.layout[i];
        const { item } = widget;
        if (!item) {
          offset++;
        } else {
          widgetsWithData.push(widget); // Se o widget tem dados, armazene-o para posterior processamento
        }
      }

      // Processar apenas os widgets com dados
      for (let i = 0; i < widgetsWithData.length; i++) {
        let widget = widgetsWithData[i];
        // Subtraimos o offset do y apenas dos widgets com dados
        widget.y -= offset;
      }

      // Atualizar o layout com os widgets com dados e os widgets sem dados
      const result = [
        ...widgetsWithData,
        ...this.layout.filter((widget) => !widget.item),
      ];

      this.$updateWidgetLayout(result);
    },
    getProcessableWidgets(onlyRequired = false) {
      return this.layout
        .sort((first, next) => (first.y > next.y ? 1 : -1))
        .reduce((result, widget) => {
          const { item } = widget;

          let isProcessable =
            item &&
            ((item.account && this.PROCESSABLE_LIST.includes(item.type)) ||
              (item.type === MANUAL_KPI.dataType && item.updateRequired));

          if (onlyRequired && !item.updateRequired) {
            isProcessable = false;
          }

          if (isProcessable) {
            widget.loading = true;
            result.push(widget);
          }

          return result;
        }, []);
    },
    verifyWidget(widget) {
      const verifiedWidget = verifyLanguage(widget, this.language);
      return verifiedWidget;
    },
  },
};
