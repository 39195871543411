<template>
  <form class="dg-select-dashboard" ref="form">
    <DgAutocomplete
      class="dg-select-dashboard__autocomplete"
      v-model="form.dashboardId"
      :options="dashboards"
      :prependIconColor="portalColor"
      :portalColor="portalColor"
      key-label="name"
      key-value="id"
      :is-portal="true"
      prependIcon="LayoutDashboard"
      label="PORTAL.HEADER.SELECT_DASHBOARD"
      :searchable="false"
      @input="selectDashboard"
      :style="globalStyle"
    />
  </form>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { ACTIONS } from "@/constants/vuex";
import { DgAutocomplete } from "@engenharia/dashgoo-ui";
export default {
  name: "SelectDashboard",
  components: {
    DgAutocomplete,
  },
  computed: {
    ...mapState("user", ["dashboards"]),
    ...mapState("dashboard", ["selectedDashboard"]),
    ...mapState("portal", ["settings"]),
    portalColor() {
      const portalColor = this.settings?.portalColor;
      return portalColor;
    },
  },
  methods: {
    ...mapActions({
      getDashboard: `dashboard/${ACTIONS.DASHBOARD.GET_DASHBOARD}`,
    }),
    selectDashboard() {
      console.log("selectDashboard");
      this.getDashboard(this.form);
      this.$parent?.$parent?.$parent?.getClientLogo();
    },
  },
  async mounted() {
    this.form.dashboardId = await this.selectedDashboard;

    this.globalStyle = {
      "--autocomplete-text-color": "#565656",
      "--autocomplete-text-color-rgba": "#56565665",
      "--autocomplete-border-color-rgba": "#56565630",
    };
  },
  data: () => ({
    form: {
      dashboardId: undefined,
    },
    globalStyle: null,
  }),
  watch: {
    selectedDashboard: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.dashboardId = newValue;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-select-dashboard {
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 317px;
  }
  &::v-deep {
    & > .dg-autocomplete--portal {
      .multiselect__tags {
        --autocomplete-border-color: #ecedf4 !important;
      }
    }
  }
  & > .dg-select-dashboard__autocomplete {
    background-color: #ecedf4 !important;
    border-radius: 9px;
    width: 100%;
  }
}
</style>
