export default {
  data: () => ({
    isFullscreen: false,
  }),
  mounted() {
    this.$root.$on("fullscreen", (fullscreen) => {
      if (this.isFullscreen === fullscreen) return;
      this.isFullscreen = fullscreen;
    });
  },
  methods: {
    setFullscreen(fullscreen) {
      this.isFullscreen = fullscreen;

      const main = document.querySelector("main");
      main.style.position = fullscreen ? "absolute" : "initial";
      main.style.top = "0";
      main.style.zIndex = "3";

      this.$root.$emit("fullscreen", fullscreen);
    },
  },
};
