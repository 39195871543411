<template>
  <div v-show="isLoading" class="user-on__container">
    <img src="/images/loading.gif" />
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "LayoutUserOnLoader",
  computed: {
    ...mapState("loading", ["isLoading"]),
  },
  watch: {
    isLoading(newValue) {
      const body = document.querySelector("body");
      body.style.overflowY = newValue ? "hidden" : "initial";
    },
  },
};
</script>
<style scoped>
.user-on__container {
  height: 100%;
  z-index: 1024;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
</style>
