import {
  DAY_BEFORE_YESTERDAY,
  LAST_MONTH,
  LAST_WEEK_MON_SUN,
  LAST_WEEK_SUN_SAT,
  MONTH_BEFORE_LAST,
  PREVIOUS_PERIOD,
  SAME_PERIOD_LAST_YEAR,
  THIS_MONTH,
  YESTERDAY,
} from "@/constants/timePeriod";
import dayjs from "@/lib/dayjs";

class TimePeriodValidation {
  constructor({ timePeriod, dateRange = [] }) {
    this._timePeriod = timePeriod;
    this._dateRange = [dateRange[0], dateRange[1]];
    this._isValid = false;
  }

  _valid() {
    this._isValid = true;
    return this;
  }

  isValid() {
    return this._isValid;
  }

  dateRange() {
    return [this._dateRange[0].toDate(), this._dateRange[1].toDate()];
  }

  thisMonth() {
    if (this._isValid || this._timePeriod !== THIS_MONTH.value) return this;

    this._dateRange = [dayjs().startOf("month"), dayjs().endOf("month")];

    return this._valid();
  }

  weeks() {
    if (this._isValid) return this;
    const regex = /^last-week-?(?<week>\w+)$/.exec(this._timePeriod);

    const week = regex?.groups?.week;
    if (!week) return this;

    const weekPosition = Number(week !== "sun");

    this._dateRange = [
      dayjs()
        .startOf("week")
        .add(weekPosition, "day")
        .startOf("day")
        .subtract(1, "week"),

      dayjs()
        .startOf("week")
        .add(weekPosition, "day")
        .startOf("day")
        .subtract(1, "millisecond"),
    ];

    return this._valid();
  }

  days() {
    if (this._isValid) return this;
    const regex = /^last-?(?<days>\w+)-days$/.exec(this._timePeriod);
    const days = Number(regex?.groups?.days || 0);
    if (!days) return this;
    this._dateRange = [
      dayjs().startOf("day").subtract(days, "days"),
      dayjs().startOf("day").subtract(1, "millisecond"),
    ];

    return this._valid();
  }

  months() {
    if (this._isValid) return this;
    const regex = /^last-?(?<months>\w+)-months$/.exec(this._timePeriod);
    const months = Number(regex?.groups?.months || 0);
    if (!months) return this;

    this._dateRange = [
      dayjs().subtract(months, "months").startOf("month"),
      dayjs().subtract(1, "months").endOf("month"),
    ];

    return this._valid();
  }

  lastWeekSun() {
    if (this._isValid || this._timePeriod !== LAST_WEEK_SUN_SAT.value)
      return this;

    this._dateRange = [
      dayjs().subtract(1, "week").day(0),
      dayjs().subtract(1, "week").day(6),
    ];

    return this._valid();
  }

  lastWeekMonSun() {
    if (this._isValid || this._timePeriod !== LAST_WEEK_MON_SUN.value)
      return this;

    this._dateRange = [dayjs().subtract(1, "week").day(1), dayjs().day(0)];

    return this._valid();
  }

  lastMonth() {
    if (this._isValid || this._timePeriod !== LAST_MONTH.value) return this;

    this._dateRange = [
      dayjs().subtract(1, "months").startOf("month"),
      dayjs().subtract(1, "months").endOf("month"),
    ];

    return this._valid();
  }

  beforeLastMonth() {
    if (this._isValid || this._timePeriod !== MONTH_BEFORE_LAST.value)
      return this;

    this._dateRange = [
      dayjs().subtract(2, "months").startOf("month"),
      dayjs().subtract(2, "months").endOf("month"),
    ];

    return this._valid();
  }

  yesterday() {
    if (this._isValid || this._timePeriod !== YESTERDAY.value) return this;

    this._dateRange = [
      dayjs().startOf("day").subtract(1, "day"),
      dayjs().startOf("day").subtract(1, "millisecond"),
    ];

    return this._valid();
  }

  beforeYesterday() {
    if (this._isValid || this._timePeriod !== DAY_BEFORE_YESTERDAY.value)
      return this;

    this._dateRange = [
      dayjs().startOf("day").subtract(2, "days"),
      dayjs().subtract(1, "day").startOf("day").subtract(1, "millisecond"),
    ];

    return this._valid();
  }

  thisYear() {
    if (this._isValid || this._timePeriod !== "this-year") return this;

    this._dateRange = [dayjs().startOf("year"), dayjs().endOf("year")];

    return this._valid();
  }

  previousPeriod() {
    if (this._isValid || this._timePeriod !== PREVIOUS_PERIOD.value)
      return this;

    const [start, end] = this._dateRange;
    const dateStart = dayjs(start, "L");
    const dateEnd = dayjs(end, "L");
    const dateRangeDiff = dateEnd.diff(dateStart, "days") + 1;

    this._dateRange = [
      dateStart.subtract(dateRangeDiff, "day"),
      dateEnd.subtract(dateRangeDiff, "day"),
    ];

    return this._valid();
  }

  samePeriodLastYear() {
    if (this._isValid || this._timePeriod !== SAME_PERIOD_LAST_YEAR.value)
      return this;

    const [start, end] = this._dateRange;
    const dateStart = dayjs(start, "L");
    const dateEnd = dayjs(end, "L");

    this._dateRange = [dateStart.subtract(1, "y"), dateEnd.subtract(1, "y")];

    return this._valid();
  }

  startIsAfterToday() {
    const [start] = this._dateRange;
    const dateStart = dayjs(start, "L");
    const dateStartIsAfterToday = dateStart.isAfter(dayjs());
    return dateStartIsAfterToday;
  }

  validationMaxDiff(maxDiffDays) {
    const [start, end] = this._dateRange;
    const dateStart = dayjs(start, "L");
    const dateEnd = dayjs(end, "L");
    const dateDiffStartAndEnd = dateEnd.diff(dateStart, "days");
    return dateDiffStartAndEnd > maxDiffDays;
  }
}

export default TimePeriodValidation;
