<template>
  <form
    class="period-form"
    ref="form"
    @submit.prevent="onSubmit"
    @keydown.enter.prevent="onSubmit"
  >
    <h3>{{ $t("FILTER") }}</h3>
    <DgIcon
      name="X"
      class="period-form__close-mobile-filter"
      @click="closeMobileFilter"
    />
    <ValidationObserver ref="validation" class="validation-observer-container">
      <DgAutocomplete
        v-model="form.timePeriod"
        group-label="label"
        group-values="group"
        key-value="value"
        key-label="label"
        :is-portal="true"
        prepend-icon="CalendarStats"
        :portalColor="portalSettings.portalColor"
        :label="$t('TIME_PERIOD')"
        :options="timePeriodList"
        :language="language"
      />
      <ValidationProvider
        name="DATE_TIME_PERIOD"
        rules="dateRangeRequired"
        v-slot="{ errors }"
        class="validation-provider-container"
      >
        <DgPortalDatepicker
          v-model="form.dateRange"
          ref="DATE_TIME_PERIOD"
          name="DATE_TIME_PERIOD"
          autofocus
          range
          :portalColor="portalSettings.portalColor"
          :language="language.toLowerCase()"
          :error="errors[0]"
          :label="$t('DATE_TIME_PERIOD')"
          :disabled="disabled.dateRange"
        />
      </ValidationProvider>
      <DgAutocomplete
        v-model="form.comparisonTimePeriod"
        :is-portal="true"
        prepend-icon="ChartAreaLine"
        :label="$t('COMPARISON_TIME_PERIOD')"
        :portalColor="portalSettings.portalColor"
        :options="comparisonTimePeriod"
        :language="language"
      />
      <ValidationProvider
        name="DATE_COMPARISON_TIME_PERIOD"
        rules="dateRangeRequired"
        v-slot="{ errors }"
        class="validation-provider-container"
      >
        <DgPortalDatepicker
          v-model="form.comparisonDateRange"
          ref="DATE_COMPARISON_TIME_PERIOD"
          name="DATE_COMPARISON_TIME_PERIOD"
          autofocus
          range
          align="right"
          :portalColor="portalSettings.portalColor"
          :language="language.toLowerCase()"
          :error="errors[0]"
          :label="$t('DATE_COMPARISON_TIME_PERIOD')"
          :disabled="disabled.comparisonDateRange"
        />
      </ValidationProvider>
      <DgPortalButton
        type="submit"
        :label="$t('PORTAL.FILTER.BUTTON_ACTION', language)"
        icon="ArrowNarrowRight"
        iconPosition="right"
        portalColor="#ECEDF4"
        size="sm"
      />
    </ValidationObserver>
  </form>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { DEFAULT_LANGUAGE } from "@/constants/language";
import {
  CUSTOM,
  PREVIOUS_PERIOD,
  getTranslatedTimePeriod,
  getTranslatedComparisonTimePeriod,
} from "@/constants/timePeriod";
import { ACTIONS } from "@/constants/vuex";
import dayjs from "@/lib/dayjs";
import MixinUpdateWidgets from "@/mixins/updateWidgets";
import TimePeriodValidation from "@/utils/timePeriodValidation";
import {
  DgAutocomplete,
  DgPortalDatepicker,
  DgPortalButton,
  DgIcon,
} from "@engenharia/dashgoo-ui";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "SelectPeriod",
  mixins: [MixinUpdateWidgets],
  components: {
    DgPortalDatepicker,
    DgAutocomplete,
    DgPortalButton,
    DgIcon,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    dateRangeErrors: {
      timePeriod: "",
      comparisonTimePeriod: "",
    },
    disabled: {
      dateRange: false,
      comparisonDateRange: false,
    },
    form: {
      dateRange: [],
      comparisonDateRange: [],
      comparisonTimePeriod: undefined,
      timePeriod: undefined,
    },
  }),
  computed: {
    ...mapState("dashboard", ["settings", "dashboardLanguage"]),
    ...mapState({
      portalSettings: (state) => state.portal?.settings,
    }),
    timePeriodList() {
      return getTranslatedTimePeriod();
    },
    comparisonTimePeriod() {
      return getTranslatedComparisonTimePeriod();
    },
    language() {
      return this.dashboardLanguage || DEFAULT_LANGUAGE;
    },
  },
  mounted() {
    this.initValues();
  },
  methods: {
    ...mapActions({
      handleSelectPeriod: `dashboard/${ACTIONS.DASHBOARD.SELECT_PERIOD}`,
    }),
    initValues() {
      const baseInitialDate = dayjs(this.settings?.dateRangeStart)
        .utc()
        .format("YYYY-MM-DD");
      const baseFinalDate = dayjs(this.settings?.dateRangeEnd)
        .utc()
        .format("YYYY-MM-DD");

      const comparisonBaseInitialDate = dayjs(
        this.settings?.comparisonDateRangeStart
      )
        .utc()
        .format("YYYY-MM-DD");
      const comparisonbaseFinalDate = dayjs(
        this.settings?.comparisonDateRangeEnd
      )
        .utc()
        .format("YYYY-MM-DD");

      const initialDate = dayjs(baseInitialDate).toDate();
      const finalDate = dayjs(baseFinalDate).toDate();
      const comparisonInitialDate = dayjs(comparisonBaseInitialDate).toDate();
      const comparisonFinalDate = dayjs(comparisonbaseFinalDate).toDate();

      this.form.dateRange = [initialDate, finalDate];
      this.form.comparisonDateRange = [
        comparisonInitialDate,
        comparisonFinalDate,
      ];
      this.form.timePeriod = this.settings?.timePeriod || CUSTOM.value;
      this.form.comparisonTimePeriod =
        this.settings?.comparisonTimePeriod || PREVIOUS_PERIOD.value;

      return this.form;
    },
    timePeriodValidation() {
      const timePeriod = this.form.timePeriod;
      if (timePeriod === CUSTOM.value) {
        this.disabled.dateRange = false;
        return;
      }
      this.disabled.dateRange = true;
      this.form.dateRange = new TimePeriodValidation({
        timePeriod,
      })
        .thisMonth()
        .lastMonth()
        .lastWeekMonSun()
        .lastWeekSun()
        .beforeLastMonth()
        .yesterday()
        .beforeYesterday()
        .thisYear()
        .days()
        .months()
        .dateRange();
    },
    timePeriodComparisonValidation() {
      const comparisonTimePeriod = this.form.comparisonTimePeriod;
      if (comparisonTimePeriod === CUSTOM.value) {
        this.disabled.comparisonDateRange = false;
        return;
      }

      this.disabled.comparisonDateRange = true;

      this.form.comparisonDateRange = new TimePeriodValidation({
        timePeriod: comparisonTimePeriod,
        dateRange: this.form.dateRange,
      })
        .previousPeriod()
        .samePeriodLastYear()
        .dateRange();
    },
    timePeriodValidationCustom() {
      this.dateRangeErrors.timePeriod = "";
      const timePeriodValidation = new TimePeriodValidation({
        dateRange: this.form.dateRange,
      });
      const dateStartIsAfterToday = timePeriodValidation.startIsAfterToday();
      if (dateStartIsAfterToday) {
        this.dateRangeErrors.timePeriod = this.$t("ERRORS.DATE_MORE_TODAY");
        return;
      }

      const MAX_DIFF_DAYS = 185;
      const dateDiffStartAndEnd =
        timePeriodValidation.validationMaxDiff(MAX_DIFF_DAYS);
      if (dateDiffStartAndEnd > MAX_DIFF_DAYS) {
        this.dateRangeErrors.timePeriod = this.$t("DATE_MAX_ALLOWED", {
          maxDiffDays: MAX_DIFF_DAYS,
        });
      }
    },
    timePeriodComparisonValidationCustom() {
      this.dateRangeErrors.comparisonTimePeriod = "";
      const timePeriodValidation = new TimePeriodValidation({
        dateRange: this.form.comparisonDateRange,
      });
      const dateStartIsAfterToday = timePeriodValidation.startIsAfterToday();
      if (dateStartIsAfterToday) {
        this.dateRangeErrors.comparisonTimePeriod = this.$t(
          "ERRORS.DATE_MORE_TODAY"
        );
        return;
      }

      const MAX_DIFF_DAYS = 185;
      const dateDiffStartAndEnd =
        timePeriodValidation.validationMaxDiff(MAX_DIFF_DAYS);
      if (dateDiffStartAndEnd > MAX_DIFF_DAYS) {
        this.dateRangeErrors.comparisonTimePeriod = this.$t(
          "ERRORS.DATE_MAX_ALLOWED",
          { maxDiffDays: MAX_DIFF_DAYS }
        );
      }
    },
    closeMobileFilter() {
      const mobileFilterMenu = this.$parent.$refs["header-period-container"];
      mobileFilterMenu.classList.toggle(
        "dg-layout-portal-header-period--mobile-opened"
      );
    },
    async onSubmit() {
      const isValid = this.$refs.form.validation
        ? await this.$refs.form.validation.validate()
        : true;

      if (isValid) {
        try {
          await this.handleSelectPeriod(this.form);

          await this.selectAndProcessWidgets(false);

          this.closeMobileFilter();
        } catch (e) {
          this.$notifyFailed({
            text: "ERROR_SOMETHING_IS_WRONG",
          });
        }
      }
    },
  },
  watch: {
    "form.dateRange": function () {
      if (this.form.timePeriod === CUSTOM.value)
        this.timePeriodValidationCustom();
      this.timePeriodComparisonValidation();
    },
    "form.comparisonDateRange": function () {
      if (this.form.comparisonTimePeriod === CUSTOM.value)
        this.timePeriodComparisonValidationCustom();
    },
    "form.timePeriod": function () {
      this.timePeriodValidation();
      this.timePeriodComparisonValidation();
    },
    "form.comparisonTimePeriod": function () {
      this.timePeriodComparisonValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
.validation-observer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.validation-provider-container {
  flex: 0 1 100%;
  max-width: 261px;
  position: relative;
  height: 58px;
  width: 100%;
}

.period-form {
  display: flex;
  align-items: center;
  // flex-direction: column;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  & > .period-form__close-mobile-filter {
    display: block;
    cursor: pointer;
    color: #ecedf4;
  }
  @media (min-width: 768px) {
    & > .period-form__close-mobile-filter {
      display: none;
    }
  }
  // @media (min-width: 768px) {
  //   flex-direction: row;
  // }
}

h3 {
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 44px;
  color: #ffffff;
  flex: 0 1 65%;
  @media (min-width: 768px) {
    display: none;
  }
}
</style>
