<template>
  <DgLayoutPortalMain
    :logoFull="clientDashboardLogo"
    position="initial"
    :portalColor="portalSettings.portalColor"
    :style="style"
    :searchable="false"
    :expand="false"
  >
    <template v-slot:main-action>
      <Dashboard />
    </template>
    <template v-slot:header-actions>
      <div class="dg-layout-action__container">
        <div class="action" @click="setFullscreen(true)">
          <DgIcon class="dg-layout-action__container-icon" name="Maximize" />
          <p class="dg-layout-action__container-icon-label">
            {{ $t("PORTAL.HOME.FULL_SCREEN") }}
          </p>
        </div>
        <div class="action" @click="handleOpen(true, options.CONTACT.key)">
          <DgIcon class="dg-layout-action__container-icon" name="Mail" />
          <p class="dg-layout-action__container-icon-label">
            {{ $t("PORTAL.HOME.TALK_TO_US") }}
          </p>
        </div>
        <div
          class="action"
          @click="handleOpen(true, options.CHANGE_PASSWORD.key)"
        >
          <DgIcon class="dg-layout-action__container-icon" name="Lock" />
          <p class="dg-layout-action__container-icon-label">
            {{ $t("PORTAL.HOME.CHANGE_PASSWORD") }}
          </p>
        </div>
        <div class="action" @click="handleLogout">
          <DgIcon class="dg-layout-action__container-icon" name="Logout" />
          <p class="dg-layout-action__container-icon-label">
            {{ $t("PORTAL.HOME.LOGOUT") }}
          </p>
        </div>
      </div>
    </template>
    <template v-slot:period>
      <SelectPeriod v-if="!isLoading" />
    </template>
    <template v-slot:mobile-menu-actions>
      <DgLayoutPortalFooterActions @handleOpen="handleOpen" />
    </template>
    <DgPortalModal
      v-model="open"
      :title="$t(selected.title)"
      :subTitle="$t(selected.subTitle)"
      :icon="selected.icon"
      :iconColor="portalSettings.portalColor"
      size="md"
    >
      <keep-alive>
        <component
          ref="component"
          :is="selected.component"
          :class="selected.class"
        />
      </keep-alive>
      <template v-if="!selected.hideActionButton" v-slot:footer>
        <DgPortalButton
          full-width
          :label="selected.submit || 'SAVE'"
          :portalColor="portalSettings.portalColor"
          @click="onSubmit"
        />
      </template>
    </DgPortalModal>
    <Loader v-show="isLoading" />
    <router-view />
  </DgLayoutPortalMain>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Dashboard from "@/components/dashboard.vue";
import Loader from "@/components/loader.vue";
import SelectPeriod from "@/components/period.vue";
import { ACTIONS } from "@/constants/vuex";
import { fullscreen } from "@/mixins";
import store from "@/store";
import {
  DgLayoutPortalMain,
  DgIcon,
  DgPortalModal,
  DgPortalButton,
  DgLayoutPortalFooterActions,
} from "@engenharia/dashgoo-ui";

export default {
  name: "LayoutLogged",
  mixins: [fullscreen],
  components: {
    Loader,
    DgIcon,
    DgPortalButton,
    DgPortalModal,
    DgLayoutPortalMain,
    DgLayoutPortalFooterActions,
    Dashboard,
    SelectPeriod,
  },
  data: () => ({
    open: false,
    fullScreen: false,
    selected: {},
    style: null,
  }),
  async mounted() {
    if (this.portalSettings?.domainName && store.state.user.logged.id) {
      const userFromStore = store.state.user;
      document.title = `${this.$t("MY_PANEL")} | ${this.agencyName}`;

      if (
        this.portalSettings.subscriber !== userFromStore.logged.subscriberId
      ) {
        this.$router.push("/not-found");
      }
    } else {
      this.$router.push("/not-found");
    }

    const portalColor = this.portalSettings.portalColor;

    this.style = {
      "--logged-hover-text-color": portalColor,
    };
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
    ...mapState({
      dashboardSettings: (state) => state.dashboard,
      portalSettings: (state) => state.portal?.settings,
    }),
    agencyName() {
      return this.portalSettings.agencyName;
    },
    clientDashboardLogo: {
      get() {
        return this.dashboardSettings?.item?.settings?.clientLogo;
      },
      set(newLogo) {
        return newLogo;
      },
    },
    options() {
      return {
        CONTACT: {
          key: "CONTACT",
          title: `${this.$t("TALK_TO")}`,
          subTitle: this.agencyName,
          icon: "Mail",
          submit: "SEND_MESSAGE",
          action: `dashboard/${ACTIONS.DASHBOARD.CONTACT}`,
          hideActionButton: false,
          component: () => import("@/components/contact"),
        },
        CHANGE_PASSWORD: {
          key: "CHANGE_PASSWORD",
          title: `${this.$t("PASSWORD")}`,
          subTitle: `${this.$t("PORTAL.MODAL.CHANGE_PASSWORD.CHANGE")}`,
          icon: "Lock",
          submit: "CHANGE_PASSWORD",
          action: `user/${ACTIONS.USER.CHANGE_PASSWORD}`,
          hideActionButton: false,
          component: () => import("@/components/changePassword"),
        },
        DASHBOARD: {
          key: "DASHBOARD",
          title: `${this.$t("DASHBOARD")}`,
          subTitle: "",
          icon: "LayoutDashboard",
          hideActionButton: true,
          class: "dg-dashboard-layout-modal",
          component: () => import("@/components/dashboard"),
        },
      };
    },
  },
  methods: {
    ...mapActions({
      handleLogout: `user/${ACTIONS.USER.LOGOUT}`,
    }),
    async getClientLogo() {
      const clientLogoFromStore =
        this.dashboardSettings?.item?.settings?.clientLogo;

      return clientLogoFromStore;
    },
    handleOpen(open, key) {
      this.open = open;
      this.selected = open ? this.options[key] : {};
    },
    async onSubmit() {
      const { action } = this.selected;
      const { form, onSubmit } = this.$refs.component;
      const isValid = this.$refs.component.$refs.validation
        ? await this.$refs.component.$refs.validation.validate()
        : true;

      if (isValid) {
        this.handleOpen(false);
        try {
          await this.$store.dispatch(action, form).then(() => {
            this.clientDashboardLogo = this.getClientLogo();
          });
          if (onSubmit) {
            onSubmit();
          }
        } catch (e) {
          this.$notifyFailed({
            text: "ERROR_SOMETHING_IS_WRONG",
          });
        }
      }
    },
  },
  watch: {
    "dashboardSettings.item": {
      handler(newValue) {
        if (newValue && newValue.settings) {
          this.clientDashboardLogo = this.getClientLogo();
        }
      },
    },
  },
};
</script>
<style lang="scss">
.dg-layout-action__container {
  display: flex;
  align-items: center;
  color: var(--text-color-hint);
  & > .action {
    border: none;
    background: white;
    width: auto;
    justify-content: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #6c6c6c;
    padding: var(--spacing-lg);
    gap: 10px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-style: normal;
    transition: color 275ms cubic-bezier(0.1, 0.2, 0.3, 0.4);
    &:hover {
      color: var(--logged-hover-text-color);
      transition: color 275ms cubic-bezier(0.1, 0.2, 0.3, 0.4);
    }
  }
}

.dg-dashboard-layout > .dg-layout-main > main {
  padding-left: 32px !important;
}

.dg-header__logo.open {
  height: 60px;

  & > img {
    width: inherit;
    height: inherit;
  }
}
.dg-select-dashboard.dg-dashboard-layout-modal {
  width: auto;
  justify-content: center;
}
</style>
