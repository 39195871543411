import i18n from "@/lib/i18n";

export const THIS_MONTH = {
  label: i18n.t("THIS_MONTH", i18n.locale),
  value: "this-month",
};
export const LAST_MONTH = {
  label: i18n.t("LAST_MONTH", i18n.locale),
  value: "last-month",
};
export const MONTH_BEFORE_LAST = {
  label: i18n.t("MONTH_BEFORE_LAST", i18n.locale),
  value: "month-before-last",
};
export const LAST_3_MONTHS = {
  label: i18n.t("LAST_3_MONTHS", i18n.locale),
  value: "last-3-months",
};
export const LAST_6_MONTHS = {
  label: i18n.t("LAST_6_MONTHS", i18n.locale),
  value: "last-6-months",
};
export const LAST_7_DAYS = {
  label: i18n.t("LAST_7_DAYS", i18n.locale),
  value: "last-7-days",
};
export const LAST_14_DAYS = {
  label: i18n.t("LAST_14_DAYS", i18n.locale),
  value: "last-14-days",
};
export const LAST_30_DAYS = {
  label: i18n.t("LAST_30_DAYS", i18n.locale),
  value: "last-30-days",
};
export const YESTERDAY = {
  label: i18n.t("YESTERDAY", i18n.locale),
  value: "yesterday",
};
export const DAY_BEFORE_YESTERDAY = {
  label: i18n.t("DAY_BEFORE_YESTERDAY", i18n.locale),
  value: "day-before-yesterday",
};
export const LAST_WEEK_SUN_SAT = {
  label: i18n.t("LAST_WEEK_SUN_SAT", i18n.locale),
  value: "last-week-sun",
};
export const LAST_WEEK_MON_SUN = {
  label: i18n.t("LAST_WEEK_MON_SUN", i18n.locale),
  value: "last-week-mon",
};
export const CUSTOM = {
  label: i18n.t("CUSTOM", i18n.locale),
  value: "custom",
};
export const PREVIOUS_PERIOD = {
  label: i18n.t("PREVIOUS_PERIOD", i18n.locale),
  value: "previous",
};
export const SAME_PERIOD_LAST_YEAR = {
  label: i18n.t("SAME_PERIOD_LAST_YEAR", i18n.locale),
  value: "last-year",
};

export const TIME_PERIOD = [
  {
    label: i18n.t("CUSTOM", i18n.locale),
    group: [CUSTOM],
  },
  {
    label: i18n.t("LAST_N_DAYS", i18n.locale),
    group: [LAST_7_DAYS, LAST_14_DAYS, LAST_30_DAYS],
  },
  {
    label: i18n.t("MONTH", i18n.locale),
    group: [
      THIS_MONTH,
      LAST_MONTH,
      MONTH_BEFORE_LAST,
      LAST_3_MONTHS,
      LAST_6_MONTHS,
    ],
  },
  {
    label: i18n.t("DAYS", i18n.locale),
    group: [YESTERDAY, DAY_BEFORE_YESTERDAY],
  },
  {
    label: i18n.t("LAST_WEEK", i18n.locale),
    group: [LAST_WEEK_SUN_SAT, LAST_WEEK_MON_SUN],
  },
];

function getThisMonth() {
  return {
    label: i18n.t("THIS_MONTH", i18n.locale),
    value: "this-month",
  };
}

function getLastMonth() {
  return {
    label: i18n.t("LAST_MONTH", i18n.locale),
    value: "last-month",
  };
}

function getMonthBeforeLast() {
  return {
    label: i18n.t("MONTH_BEFORE_LAST", i18n.locale),
    value: "month-before-last",
  };
}

function getLast3Months() {
  return {
    label: i18n.t("LAST_3_MONTHS", i18n.locale),
    value: "last-3-months",
  };
}

function getLast6Months() {
  return {
    label: i18n.t("LAST_6_MONTHS", i18n.locale),
    value: "last-6-months",
  };
}

function getLast7Days() {
  return {
    label: i18n.t("LAST_7_DAYS", i18n.locale),
    value: "last-7-days",
  };
}

function getLast14Days() {
  return {
    label: i18n.t("LAST_14_DAYS", i18n.locale),
    value: "last-14-days",
  };
}

function getLast30Days() {
  return {
    label: i18n.t("LAST_30_DAYS", i18n.locale),
    value: "last-30-days",
  };
}

function getYesterday() {
  return {
    label: i18n.t("YESTERDAY", i18n.locale),
    value: "yesterday",
  };
}

function getDayBeforeYesterday() {
  return {
    label: i18n.t("DAY_BEFORE_YESTERDAY", i18n.locale),
    value: "day-before-yesterday",
  };
}

function getLastWeekSunSat() {
  return {
    label: i18n.t("LAST_WEEK_SUN_SAT", i18n.locale),
    value: "last-week-sun",
  };
}

function getLastWeekMonSun() {
  return {
    label: i18n.t("LAST_WEEK_MON_SUN", i18n.locale),
    value: "last-week-mon",
  };
}

function getCustom() {
  return {
    label: i18n.t("CUSTOM", i18n.locale),
    value: "custom",
  };
}

function getPreviousPeriod() {
  return {
    label: i18n.t("PREVIOUS_PERIOD", i18n.locale),
    value: "previous",
  };
}

function getSamePeriodLastYear() {
  return {
    label: i18n.t("SAME_PERIOD_LAST_YEAR", i18n.locale),
    value: "last-year",
  };
}

export const getTranslatedTimePeriod = () => {
  return [
    {
      label: i18n.t("CUSTOM", i18n.locale),
      group: [getCustom()],
    },
    {
      label: i18n.t("LAST_N_DAYS", i18n.locale),
      group: [getLast7Days(), getLast14Days(), getLast30Days()],
    },
    {
      label: i18n.t("MONTH", i18n.locale),
      group: [
        getThisMonth(),
        getLastMonth(),
        getMonthBeforeLast(),
        getLast3Months(),
        getLast6Months(),
      ],
    },
    {
      label: i18n.t("DAYS", i18n.locale),
      group: [getYesterday(), getDayBeforeYesterday()],
    },
    {
      label: i18n.t("LAST_WEEK", i18n.locale),
      group: [getLastWeekSunSat(), getLastWeekMonSun()],
    },
  ];
};

export const getTranslatedComparisonTimePeriod = () => {
  return [getPreviousPeriod(), getSamePeriodLastYear(), getCustom()];
};

export const COMPARISON_TIME_PERIOD = Object.freeze([
  PREVIOUS_PERIOD,
  SAME_PERIOD_LAST_YEAR,
  CUSTOM,
]);
